// select2 overwritten
.prefix:has(#phone-number-select-registration),
.prefix:has(#phone-number-select-blog),
.prefix:has(#phone-number-select-sms) {
    font-size: 16px;
    width: 85px;
    position: absolute;
    top: 0;
    left: 0;

    select {
        border: none;
        position: absolute;
        top: 0;
        left: 0;
    }

    .select2-selection.select2-selection--single {
        padding: 10px 10px 6px 4px;
        box-shadow: inset 0 1px 2px rgba(55, 41, 43, 0.1);
        background-color: #e3e3e3;
        border-bottom: none;
    }

    .select2-container {
        @include prefix;
        width: 85px !important;

        .selection {
            position: absolute;
            top: 0;
            left: 0;
            width: 85px;
        }

        & .select2-search.select2-search--dropdown {
            display: none !important;
        }
    }
}

.form label .prefix-wrap.prefix-wrap-select {
    &.blog-help-me_prefix .select2-selection__arrow b {
        border-color: #fff transparent transparent;
    }

    &.blog-help-me_prefix .select2-container--open .select2-selection__arrow b {
        border-color: transparent transparent #fff !important;
    }

    .select2-selection__arrow b {
        transform: translateY(0);
        margin-left: 0;
    }

    input {
        padding-left: rem-calc(110);
    }
}

.select2-container {
    & .select2-results__option {
        padding-left: 12px;
        font-weight: 700;
        font-size: 14px;
    }
}

.blog-help-me_prefix {
    & > .prefix:has(#phone-number-select-blog) .select2-selection.select2-selection--single {
        background-color: #e48c12;

        .select2-selection__rendered {
            color: #fff;
        }
    }
}

.select2-container {
    .selection {
        position: relative;
    }

    .select2-selection--single {
        height: unset;
        width: 100%;
        background-color: $light-gray;
        border: none;
        border-radius: 0;
        border-bottom: 6px solid $form-gray;
        padding: 10px 30px 6px 4px;
        font-size: 16px;
        text-transform: none;
        transition: box-shadow 0.5s, border-color 0.25s ease-in-out;

        &.active {
            border-bottom: 6px solid $cc-green;
        }

        .select2-selection__placeholder {
            color: $placeholder-gray;
        }
    }
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-width: 0 6px 8px !important;
    border-color: transparent transparent #888 !important;
}

.select2-selection__arrow {
    top: 50% !important;
    transform: translateY(-50%) !important;
    right: 15px !important;

    & b {
        border-color: #888 transparent transparent;
        border-style: solid;
        border-width: 8px 6px 0 !important;
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        width: 0;
        right: 20px;
    }
}

.select2-dropdown {
    border: 1px solid $light-gray;
}

.select2-search__field {
    border-radius: 0;
    margin-bottom: 0;
}

.select2-results__group {
    background-color: #b2ce7d;
    color: #fff;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: $cc-green;
}
