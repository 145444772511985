.calc {
    &__currency {
        text-transform: none;
    }

    &__container {
        display: flex;
        margin-left: 35px;

        @media (max-width: 992px) {
            margin-left: 5%;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            margin-left: 0;
        }
    }

    &__header {
        color: #70b635;
        text-align: center;
        font-size: 24px;
        line-height: 29px;
        padding-bottom: 26px;

        @media (max-width: 768px) {
            font-size: 15px;
            line-height: 1rem;
            padding-bottom: 15px;
        }
    }

    &__discount-txt {
        line-height: 19px;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        color: #70b635;
    }

    &__discount-inpt {
        margin-left: 5px;
        width: 100px;
        border: none;
        border-bottom: 1px solid #d2d2d2;
        outline: none;
        text-align: center;
        display: inline-block;
        border-radius: unset;
        background-color: unset;
        box-shadow: unset;

        &::placeholder {
            color: #707070;
            text-align: center;
        }
    }

    &__calculation {
        background-color: $white;
        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        width: 575px;
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 40px;
        padding-right: 40px;
        position: relative;
        z-index: 3;

        @media (max-width: 768px) {
            width: 320px;
            padding: 25px 10px;
            margin: 0 auto;
        }
    }

    &__numbers {
        width: 450px;
        margin-top: 125px;
        margin-bottom: 125px;
        background-color: #fff;
        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: 30px;
        position: relative;
        z-index: 2;
        left: -5px;

        @media (max-width: 768px) {
            padding: 20px;
            margin-bottom: 195px;
        }

        @media (max-width: 768px) {
            width: 300px;
            margin: 0 auto;
            left: 0;
            visibility: hidden;
            height: 0;
            top: -255px;
            transition: all 0.4s ease;
        }

        &--block {
            visibility: visible;
            height: unset;
            top: 0;

            .calc__cels {
                visibility: visible;
            }
        }

        &--one {
            margin-top: 80px;

            @media (max-width: 768px) {
                margin-top: 0;
            }
        }
    }

    &__cels {
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        line-height: 30px;
        font-weight: 400;
        color: #707070;

        @media (max-width: 768px) {
            visibility: hidden;
        }

        &--significant {
            color: #70b635;
            font-weight: 700;
        }
    }
}

.text--distinct {
    font-weight: 700;
}
