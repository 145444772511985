@charset "utf-8";

@import '../scss/libs/bootstrap/variables';
@import 'bootstrap';

@import '../scss/functions';
@import '../scss/settings';

// Libs
@import '~select2/src/scss/core';
@import '~jquery-ui-bundle/jquery-ui.css';

@import '../scss/typography';
@import '../scss/redesign/lib/fonts/fonts';
@import '../scss/libs/bootstrap/table';

@import '../scss/redesign/lib/hamburgers/hamburgers';
@import '../scss/components/general';
@import '../scss/components/clock';
@import '../scss/components/form';
@import '../scss/components/input';
@import '../scss/components/font';
@import '../scss/components/text';
@import '../scss/components/calculator';
@import '../scss/components/contact';
@import '../scss/components/common/buttons';
@import '../scss/components/chevron';
@import '../scss/components/select2';

@import '../scss/sections/all';
@import '../scss/sections/section';

// libs
@import 'fontawesome-4.7';

// New stuffs
@import '../scss/components/rewritten/title';
@import '../scss/components/rewritten/rw-label';
@import '../scss/components/rewritten/rw-input';
@import '../scss/components/rewritten/rw-button';
@import '../scss/components/rewritten/rw-section';
@import '../scss/components/rewritten/top';

// Rewritten
@import '../scss/components/rewritten/rw-rangeslider';
@import '../scss/components/rewritten/rw-slider';
@import '../scss/components/rewritten/rw-crement';

@import '../scss/components/loader';

@import '../scss/components/autocomplete';
@import '../icons/style';

@import 'bootstrap/scss/popover';
@import '../scss/components/popover';

@import '../scss/components/bank-id';
