.popover {
    background-color: $cc-green;
    color: #ffffff;
    font-size: 1rem;
    max-width: 400px !important;

    @include media-breakpoint-down(sm) {
        max-width: 276px;
    }

    .popover-header {
        background-color: $cc-green;
        color: #ffffff;
    }
    .popover-body {
        background-color: $cc-green;
        color: #ffffff;
    }
}

.bs-popover-right > .arrow::after {
    border-right-color: $cc-green !important;
}
