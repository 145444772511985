.rangeslider {
    box-shadow: none;
    background-color: #e6e6e6;
    border-radius: 10px;

    &__handle {
        background-image: none;
        text-align: center;
        height: 38px;
        width: 145px;
        color: $dark-purple;
        line-height: 19px;
        font-size: 1rem;
        font-weight: 500;
        background-color: white;
        border: 1px solid $dark-purple;
        border-radius: 4px;
        box-shadow: 0 4px 6px rgba($dark-purple, 0.25);
        padding-top: 9px;
        padding-left: 9px;
        padding-right: 9px;
        position: relative;
        cursor: pointer;

        &:before {
            content: '<';
            position: absolute;
            left: 5px;
        }
        &:after {
            border-radius: unset;
            background-image: none;
            top: unset;
            bottom: unset;
            left: unset;
            display: unset;
            width: unset;
            height: unset;
            content: '>';
            position: absolute;
            right: 5px;
        }
    }

    &__fill {
        background-color: $dark-purple;
        position: absolute;
    }

    &--horizontal {
        height: 10px;
        width: calc(100% - 70px);
        margin-left: 35px;

        @media (max-width: 768px) {
            width: 75%;
        }
    }
}

.rangeslider--horizontal .rangeslider__handle {
    top: -14px;
}

.rangeslider--horizontal .rangeslider__fill {
    top: 0;
    height: 10px;
    border-radius: 10px;
}

.sliders-box {
    //max-width: 600px;
    max-width: 900px;
    margin: 85px auto;
}

.range-info {
    color: $dark-purple;
    position: absolute;
    top: 25px;
    left: 0;

    &--to {
        left: unset;
        right: 0;
    }
}

.slider:has(.rangeslider--horizontal) {
    background: none;
}
.slider-wrap:has(.rangeslider--horizontal) {
    margin-top: 0;
}
