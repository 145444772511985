.section-error {
    .form {
        padding: 2rem;
        border-radius: 1rem;

        @include media-breakpoint-up(lg) {
            padding: 4rem;
        }
    }
}

.section-infotext {
    text-align: center;
    color: #8c9199;
    margin: 50px auto 0 auto;
    max-width: 960px;
}
.section-contact {
    h2 {
        color: $cc-green;
        padding-top: rem-calc(20);
    }
}
