@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 100;
    font-style: normal;
    src: url(../../../../fonts/Montserrat/Montserrat-Thin.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 100;
    font-style: italic;
    src: url(../../../../fonts/Montserrat/Montserrat-ThinItalic.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 200;
    font-style: normal;
    src: url(../../../../fonts/Montserrat/Montserrat-ExtraLight.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 200;
    font-style: italic;
    src: url(../../../../fonts/Montserrat/Montserrat-ExtraLightItalic.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 300;
    font-style: normal;
    src: url(../../../../fonts/Montserrat/Montserrat-Light.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 300;
    font-style: italic;
    src: url(../../../../fonts/Montserrat/Montserrat-LightItalic.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 400;
    src: url(../../../../fonts/Montserrat/Montserrat-Regular.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 400;
    font-style: italic;
    src: url(../../../../fonts/Montserrat/Montserrat-Italic.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 500;
    src: url(../../../../fonts/Montserrat/Montserrat-Medium.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 500;
    font-style: italic;
    src: url(../../../../fonts/Montserrat/Montserrat-MediumItalic.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 600;
    src: url(../../../../fonts/Montserrat/Montserrat-SemiBold.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 600;
    font-style: italic;
    src: url(../../../../fonts/Montserrat/Montserrat-SemiBoldItalic.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 700;
    src: url(../../../../fonts/Montserrat/Montserrat-Bold.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 700;
    font-style: italic;
    src: url(../../../../fonts/Montserrat/Montserrat-BoldItalic.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 800;
    src: url(../../../../fonts/Montserrat/Montserrat-ExtraBold.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 800;
    font-style: italic;
    src: url(../../../../fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 900;
    src: url(../../../../fonts/Montserrat/Montserrat-Black.ttf) format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    font-weight: 900;
    font-style: italic;
    src: url(../../../../fonts/Montserrat/Montserrat-BlackItalic.ttf) format('ttf');
}

@font-face {
    font-family: 'Poppins';
    font-display: swap;
    font-weight: 600;
    font-style: normal;
    src: url(../../../../fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-display: swap;
    font-weight: 600;
    font-style: normal;
    src: url(../../../../fonts/Inter/Inter-VariableFont.ttf) format('truetype');
}
