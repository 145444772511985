@import 'variables';

@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?noae1z');
    src: url('fonts/icomoon.eot?noae1z#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?noae1z') format('truetype'), url('fonts/icomoon.woff?noae1z') format('woff'), url('fonts/icomoon.svg?noae1z#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}';
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-history {
    &:before {
        content: $icon-history;
    }
}
.icon-award {
    &:before {
        content: $icon-award;
        color: #fff;
    }
}
.icon-checked {
    &:before {
        content: $icon-checked;
        color: #fff;
    }
}
.icon-handheld {
    &:before {
        content: $icon-handheld;
        color: #fff;
    }
}
.icon-heart {
    &:before {
        content: $icon-heart;
        color: #fff;
    }
}
.icon-stopwatch {
    &:before {
        content: $icon-stopwatch;
        color: #fff;
    }
}
.icon-thumbs_up {
    &:before {
        content: $icon-thumbs_up;
        color: #fff;
    }
}
.icon-email {
    &:before {
        content: $icon-email;
        color: #81007f;
    }
}
.icon-chevron {
    &:before {
        content: $icon-chevron;
        color: #5ab034;
    }
}
.icon-phone {
    &:before {
        content: $icon-phone;
        color: #7a0073;
    }
}
.icon-mobile {
    &:before {
        content: $icon-mobile;
        color: #7a0073;
    }
}
.icon-clock {
    &:before {
        content: $icon-clock;
        color: #7a0073;
    }
}
