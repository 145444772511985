.clock {
    margin: rem-calc(30) auto 0 auto;
    width: rem-calc(520);
    height: rem-calc(130); /* 1:4  */

    @media screen and (max-width: 600px) {
        width: rem-calc(400);
        height: rem-calc(100);
    }

    @media screen and (max-width: 450px) {
        width: rem-calc(300);
        height: rem-calc(75);
    }

    .time {
        height: 100%;
        width: 25%;
        background-color: white;
        border-radius: rem-calc(10);
        float: left;
        line-height: rem-calc(140);
        color: $dark-purple;
        font-weight: bold;
        font-size: rem-calc(93);

        &.seconds {
            color: $cc-green;
        }

        @media screen and (max-width: 600px) {
            line-height: rem-calc(100);
            font-size: rem-calc(75);
        }

        @media screen and (max-width: 450px) {
            line-height: rem-calc(75);
            font-size: rem-calc(55);
        }
    }

    .when-time {
        height: 100%;
        width: 25%;
        background-color: white;
        border-radius: rem-calc(10);
        border: rem-calc(1) solid $cc-green;
        float: left;
        line-height: rem-calc(140);
        color: $dark-purple;
        font-weight: bold;
        font-size: rem-calc(93);

        &.seconds {
            color: $cc-green;
        }

        @media screen and (max-width: 600px) {
            line-height: rem-calc(100);
            font-size: rem-calc(75);
        }

        @media screen and (max-width: 450px) {
            line-height: rem-calc(75);
            font-size: rem-calc(55);
        }
    }

    .space {
        position: relative;
        height: 100%;
        width: 12.5%;
        float: left;

        span {
            position: absolute;
            display: block;
            background-color: white;
            border-radius: 50%;
            width: 20px;
            padding-bottom: 20px;

            @media screen and (max-width: 450px) {
                width: 10px;
                padding-bottom: 10px;
            }
        }

        span.first {
            top: calc(50% - 30px);
            left: calc(50% - 10px);

            @media screen and (max-width: 450px) {
                top: calc(50% - 15px);
                left: calc(50% - 5px);
            }
        }

        span.second {
            top: calc(50% + 10px);
            left: calc(50% - 10px);

            @media screen and (max-width: 450px) {
                top: calc(50% + 5px);
                left: calc(50% - 5px);
            }
        }
    }
}

// -------------------------- >

.text-over-clocks {
    margin-top: rem-calc(20);
    text-align: center;
    font-weight: bold;
    font-size: rem-calc(15);
}

.dashboard-clock {
    margin: rem-calc(5) auto;
    height: rem-calc(130);
    display: flex;
    margin-bottom: 25px;

    .time {
        height: 100%;
        width: 20%;
        background-color: $light-gray;
        border-radius: rem-calc(10);
        float: left;
        line-height: rem-calc(140);
        color: $dark-purple;
        font-weight: bold;
        font-size: rem-calc(80);

        &.seconds {
            color: $cc-green;

            //Clip seconds on smaller screens
            @media screen and (max-width: 500px) {
                visibility: hidden;
            }
        }
        &.red {
            color: $dark-red;
        }

        @media screen and (max-width: 800px) {
            line-height: rem-calc(120);
            font-size: rem-calc(65);
        }

        @media screen and (max-width: 700px) {
            line-height: rem-calc(50);
            font-size: rem-calc(35);
            width: 28%;
        }
    }

    .when-time {
        height: 100%;
        width: 18%;
        background-color: $light-gray;
        border-radius: rem-calc(10);
        float: left;
        line-height: rem-calc(140);
        color: $dark-purple;
        font-weight: bold;
        font-size: rem-calc(93);

        &.seconds {
            color: $cc-green;

            //Clip seconds on smaller screens
            @media screen and (max-width: 500px) {
                visibility: hidden;
            }
        }
        &.red {
            color: $dark-red;
        }

        @media screen and (max-width: 800px) {
            line-height: rem-calc(120);
            font-size: rem-calc(65);
        }

        @media screen and (max-width: 700px) {
            line-height: rem-calc(80);
            font-size: rem-calc(45);
        }
    }

    .space {
        position: relative;
        width: 5%;

        span {
            position: absolute;
            display: block;
            background-color: $dark-purple;
            border-radius: 50%;
            width: 15px;
            height: 15px;

            @media screen and (max-width: 700px) {
                width: 10px;
                height: 10px;
            }
        }

        &.last {
            > span {
                background-color: $cc-green;
            }

            //Clip seconds on smaller screens
            @media screen and (max-width: 500px) {
                visibility: hidden;
            }
        }
        &.red {
            > span {
                background-color: $dark-red;
            }
        }

        span.first {
            top: calc(50% - 30px);
            left: calc(50% - 10px);

            @media screen and (max-width: 700px) {
                top: calc(50% - 15px);
                left: calc(50% - 5px);
            }
        }

        span.second {
            top: calc(50% + 10px);
            left: calc(50% - 10px);

            @media screen and (max-width: 700px) {
                top: calc(50% + 5px);
                left: calc(50% - 5px);
            }
        }
    }
}

#count-down-timer {
    height: 8rem;
    width: 345px;
    @media screen and (max-width: 530px) {
        width: 240px;
        margin: 1.07143rem auto !important;
    }

    .space {
        width: 85px;
        @media screen and (max-width: 530px) {
            width: 40px;
        }
    }

    .seconds-counter {
        color: $cc-green;
    }

    .time {
        font-size: 5.5rem;
        line-height: 8rem;
        width: 130px;
        @media screen and (max-width: 530px) {
            font-size: 3.5rem;
            width: 100px;
        }

        p {
            text-transform: uppercase;
            font-size: 1rem;
            color: #585a5d;
            font-weight: 700;
            padding-top: 10px;
        }
    }
}
