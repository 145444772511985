.rw-section {
    &__client {
        background-color: $light-gray;
        padding-bottom: 30px;
    }

    &__heading {
        background-color: $cc-green;
        color: white;
        text-align: center;
        padding-top: 2.8rem;
        padding-bottom: 2.2rem;

        h1 {
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
                font-size: 1.8rem;
            }
        }
    }

    &__extension {
        margin-top: 3.5rem;
        background-color: white;
        border: 1px solid $form-gray;
        border-radius: 0.71429rem;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
