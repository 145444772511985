:root,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: $global-font-size-px;
}

body {
    min-width: rem-calc(320);
}

iframe {
    max-width: 100%;

    @include media-breakpoint-down(sm) {
        height: auto !important;
    }
}

.hide {
    display: none;
}

.h-slide {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    &.active {
        display: block;
        height: auto;
        opacity: 1;
    }
}

.logo {
    display: inline-block;
    width: rem-calc(160);
    height: rem-calc(55);
    background: url('../../images/redesign/logo-cool-credit.svg') 50% 50% no-repeat;
    background-size: 100% auto;
    &.dev {
        background: url('../../images/redesign/logo-cool-credit-dev.svg') 50% 50% no-repeat;
        //animation: run 5s linear infinite;
        background-size: contain;
    }
    //@keyframes run {
    //    0% {
    //        margin-left: 0;
    //    }
    //    50% {
    //        margin-left: 100px;
    //    }
    //    100% {
    //        margin-left: 0;
    //    }
    //}
}

.pagination-divider {
    font-size: 35px;
    padding-top: 10px;
    padding-left: 6px;
    width: 30px;
}

.button {
    &.main {
        display: block;
        font-size: rem-calc(14);
        font-weight: bold;
        border-radius: rem-calc(50);
        letter-spacing: rem-calc(1);
        text-transform: uppercase;
        background-image: url('../../images/ico-arrow.svg');
        background-position: right 13px center;
        max-width: rem-calc(250);
        background-repeat: no-repeat;
        background-size: auto rem-calc(20);
        padding: rem-calc(20) rem-calc(70) rem-calc(20) rem-calc(30);
        margin-bottom: rem-calc(20);

        &.checked {
            background-image: url('../../images/ico-check-white.png');
            background-position: right 20px center;
        }

        &[readonly] {
            opacity: 0.25;
            cursor: not-allowed;
        }

        @include media-breakpoint-up(md) {
            max-width: rem-calc(400);
            font-size: rem-calc(21);
            letter-spacing: rem-calc(1);
            margin-bottom: rem-calc(30);
        }

        @include media-breakpoint-up(lg) {
            max-width: none;
            display: inline-block;
            font-size: rem-calc(21);
            letter-spacing: normal;
            padding: rem-calc(15) rem-calc(60) rem-calc(15) rem-calc(20);
            position: relative;
            z-index: 2;
        }

        &,
        &:focus,
        &:active {
            outline: none;
        }
    }

    &.documents {
        display: block;
        font-size: rem-calc(16);
        font-weight: bold;
        border-radius: rem-calc(50);
        letter-spacing: rem-calc(1);
        text-transform: uppercase;
        padding: rem-calc(10) rem-calc(20) rem-calc(10) rem-calc(20);
        margin-bottom: rem-calc(20);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(34);
            letter-spacing: rem-calc(1);
            margin-bottom: rem-calc(30);
        }

        @include media-breakpoint-up(lg) {
            display: inline-block;
            font-size: rem-calc(21);
            letter-spacing: normal;
            padding: rem-calc(15) rem-calc(30) rem-calc(15) rem-calc(20);
            position: relative;
            z-index: 2;
        }

        &,
        &:focus,
        &:active {
            outline: none;
        }
    }

    &.menu {
        display: block;
        font-size: rem-calc(21);
        font-weight: bold;
        border-radius: rem-calc(50);
        letter-spacing: rem-calc(1);
        text-transform: uppercase;
        margin-bottom: rem-calc(20);
        @include media-breakpoint-up(md) {
            font-size: rem-calc(21);
            letter-spacing: rem-calc(1);
            margin-bottom: rem-calc(10);
        }

        @include media-breakpoint-up(lg) {
            display: inline-block;
            font-size: rem-calc(21);
            letter-spacing: normal;
            padding: rem-calc(15) rem-calc(60) rem-calc(15) rem-calc(20);
            position: relative;
            z-index: 2;
        }

        &,
        &:focus,
        &:active {
            outline: none;
        }
    }

    &.alert,
    &.alert:hover,
    &.alert:focus {
        color: $white;
    }

    &.purple {
        background-color: $dark-purple;

        &:focus,
        &:active {
            background-color: $darker-purple;
        }

        &:hover {
            background-color: $darker-purple;
        }
    }
}

.section {
    padding: rem-calc(50) 0 rem-calc(50) 0;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(50) 0 rem-calc(60) 0;
    }

    h2,
    h3 {
        color: $cc-green;
    }

    h2 {
        margin-bottom: rem-calc(15);

        &.smaller {
            font-size: rem-calc(20);

            @include media-breakpoint-up(md) {
                font-size: rem-calc(32);
            }

            @include media-breakpoint-up(lg) {
                font-size: rem-calc(28);
            }
        }
    }

    h3 {
        margin-top: rem-calc(20);

        @include media-breakpoint-up(md) {
            margin-top: rem-calc(35);
            font-size: rem-calc(20);
        }

        @include media-breakpoint-up(lg) {
            margin-top: rem-calc(50);
            font-size: rem-calc(18);
        }
    }

    &--purple {
        background-color: #81007f;
    }

    &--white {
        background-color: #ffffff;
    }

    &--green {
        background: $cc-green;

        &:before {
            border-color: $white transparent transparent transparent;
        }

        &--grey {
            &:before {
                border-color: $light-gray transparent transparent transparent;
            }
        }

        &.arrow--grey {
            &:before {
                border-color: #f2f2f2 transparent transparent transparent;
            }
        }
    }

    &.text-white {
        h2,
        h3,
        & {
            color: $white;
        }
    }

    &.section-red {
        background: $alert-color;
    }

    &.section-orange {
        background: $dark-orange;

        h2 {
            color: $white;
        }

        &:before {
            border-color: $white transparent transparent transparent;
        }

        &--grey {
            &:before {
                border-color: $light-gray transparent transparent transparent;
            }
        }
        &--green {
            &:before {
                border-color: $cc-green transparent transparent transparent;
            }
        }
        &.arrow-white {
            &:before {
                border-color: white transparent transparent transparent;
            }
        }
    }

    &.section-grey {
        background: $light-gray;
    }
    &.section-white {
        background: $white;
    }

    &.section-green {
        background: $cc-green;
        overflow: hidden;

        &:before {
            border-color: $white transparent transparent transparent;
        }

        &--grey {
            &:before {
                border-color: $light-gray transparent transparent transparent !important;
            }
        }
    }

    &.section-primary {
        background: $light-gray;

        & button a {
            color: #fff;
        }

        a {
            color: $cc-green;
            &.button {
                color: $white;
            }
        }
    }

    &.text-larger {
        @include media-breakpoint-up(md) {
            font-size: rem-calc(22);
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(18);
        }
    }

    .grid-container,
    .container {
        max-width: rem-calc(1080);
    }
}
/* pro blank page */
.section-block {
    padding: 0 0 40px 0;
}
.block {
    z-index: 2;
    background-color: white;
    border: 1px solid #e3e3e3;
    /*border-radius: 1.07143rem !important;*/
    position: relative;
    min-height: 1em;
    margin-bottom: 0.5rem;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.callcentrum {
    display: none;
    margin: rem-calc(2) rem-calc(10) 0 0;
    vertical-align: middle;
    line-height: 1.2;

    &.always-show {
        display: inline-block;
        margin: rem-calc(2) rem-calc(10);
    }

    @include media-breakpoint-up(md) {
        display: inline-block;
    }

    @include media-breakpoint-up(lg) {
        font-size: $global-font-size;
    }

    a {
        display: block;
        color: $cc-green;
        font-weight: bold;
        text-decoration: none;
        font-size: $global-font-size + rem-calc(4);

        @include media-breakpoint-up(md) {
            font-size: $global-font-size + rem-calc(6);
        }

        @include media-breakpoint-up(lg) {
            display: inline;
            font-size: $global-font-size + rem-calc(1);
            margin: 0 rem-calc(15);
        }
    }
}

#newsletter .form {
    background-color: transparent;
    padding: inherit;
    margin: inherit;
    border: none;
}

.horizontal-line {
    width: 100%;
    height: 1px;

    &.more-payments {
        margin: 0;
        background-color: #82ae33;
        border: unset;
        max-width: unset;
    }
}

table {
    width: 100%;
}

img {
    display: inline-block;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
}

.is-visible {
    display: block !important;
}
