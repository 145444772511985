ul.ui-widget-content {
    border: 1px solid $light-gray;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;

    .ui-menu-item {
        .ui-menu-item-wrapper {
            padding: 6px;
            cursor: pointer;

            &:hover {
                border: none;
                background-color: $cc-green;
                color: white;
            }

            &.ui-state-active {
                background-color: $cc-green;
                color: white;
            }
        }
    }
}
