.rw-button {
    &__round {
        border-radius: 3.57143rem;
        font-weight: 700;
        text-transform: uppercase;
        padding: 1.07143rem;
        cursor: pointer;

        &--info {
            background-color: $dark-purple;
            color: white;

            &:hover {
                background: darken($dark-purple, 5%);
            }
        }
    }
}
