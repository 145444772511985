#loaderBody {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 100px;
}

.loader {
    margin-top: 20px;
    position: relative;
    height: 50px;
    width: 50px;
    display: inline-block;
    animation: around 5.4s infinite;

    &-row {
        position: relative;
    }

    &-container {
        position: absolute;
        top: calc(50% - 10px);
        right: 100px;
    }
}

@keyframes around {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader::after,
.loader::before {
    content: '';
    background: transparent;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 4px;
    border-color: purple purple transparent transparent;
    border-style: solid;
    border-radius: 50px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
}

.loader::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
}
