.chevron {
    &__box {
        text-align: center;
        padding-top: 20px;
        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &--rotate {
        transform: rotate(180deg);
    }
}
