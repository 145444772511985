.btn {
    &-pagination {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 1rem;
        padding: 1rem;
        height: rem-calc(38);
        line-height: rem-calc(13);

        &-prev {
            padding: 0 2rem 0 1rem;

            &:before {
                content: ' ';
                background-image: url(../../../images/ico-slider-left.svg);
                background-size: 13px 19px;
                background-repeat: no-repeat;
                background-position: center;
                fill: $cc-green;
                height: 19px;
                width: 13px;
                padding: 1rem 1rem 0 1rem;
                display: inline-block;
            }
        }
        &-next {
            padding: 0 1rem 0 1.5rem;

            &:after {
                content: ' ';
                background-image: url(../../../images/ico-slider-right.svg);
                background-size: 13px 19px;
                background-repeat: no-repeat;
                background-position: center;
                fill: $cc-green;
                height: 19px;
                width: 13px;
                padding: 1rem 1rem 0 1rem;
                display: inline-block;
            }
        }
    }

    &--primary {
        background-color: $cc-green;
        color: $white;

        &:hover {
            background-color: $cc-green-darker;
        }
    }
    &--secondary {
        background-color: $light-gray;
        color: $black;

        &:hover {
            background-color: $lighter-gray;
        }
    }

    &__agreement {
        background-color: white;
        border-radius: 20px;
        padding: 10px;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);

        &:hover {
            cursor: pointer;
            background-color: darken(white, 10%);
        }
    }

    &__link {
        text-decoration: underline;

        &:hover {
            cursor: pointer;
            text-decoration: none;
        }
    }
}

.btns__cta {
    display: inline-block;
    border: none;
    border-radius: 4px;
    background-color: #c32d2c;
    color: #fff;
    line-height: 19px;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 65px 15px 50px;
    transition: all 0.4s ease;
    position: relative;
    box-shadow: 0 4px 12px rgba(195, 45, 44, 0.4);

    &:hover {
        background-color: #d23837;
        text-decoration: none;
    }
}

.button {
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 0.7142857143rem;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    font-size: 0.9rem;
    line-height: 1;
    margin: 0 0 1rem;
    padding: 0.65em 1em;
    text-align: center;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    vertical-align: middle;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    &.primary {
        background-color: $primary-color;
        color: #fff;

        &:hover {
            background-color: $cc-green-darker;
            color: #fff;
        }
    }

    &.alert {
        background-color: $alert-color;
        color: #fff;

        &:hover {
            background-color: $darker-red;
            color: #fff;
        }
    }

    &.info {
        background-color: $info-color;
        color: #fff;

        &:hover {
            background-color: $darker-purple;
            color: #fff;
        }
    }
}

button {
    appearance: none;
    background: transparent;
    border: 0;
    border-radius: 0.7142857143rem;
    cursor: auto;
    line-height: 1;
    padding: 0;
}
