body {
    font-family: $body-font-family;
}

section {
    .clear-fix-xs {
        height: 5px;
    }

    .clear-fix-sm {
        height: 10px;
    }

    .clear-fix-md {
        height: 20px;
    }

    .clear-fix-lg {
        height: 40px;
    }
}

.clearfix:after {
    display: block;
    content: '';
    clear: both;
}

#banks {
    h2 {
        text-transform: none;
    }

    .banks {
        margin: rem-calc(20) 0;

        @include media-breakpoint-up(lg) {
            margin: rem-calc(40) 0;
        }

        img {
            margin: rem-calc(10);
            height: rem-calc(55);
        }
    }
}

.christmas,
.christmas-sale {
    #chat .widget {
        background-color: $cc-green;
        box-shadow: rem-calc(1) rem-calc(2) rem-calc(9) rem-calc(2) #777777;
    }
}

#calculator-registration {
    .text--sale.text--strike {
        display: none;
    }
}

#form .form-group i.fa {
    float: right;
    margin-top: -39px;
    margin-right: 15px;
    font-size: 24px;

    &.red {
        color: #c32d2c;
    }

    &.fa-times {
        color: #707070;
        cursor: pointer;
    }
}

#calculator-red {
    padding-top: rem-calc(0);
    padding-bottom: rem-calc(30);
    background: $red;
    color: $white;
    width: 100%;
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding-top: rem-calc(10);
    }

    .calculator-red {
        &__img {
            z-index: 1;

            img {
                width: rem-calc(300);
                max-width: rem-calc(340);
                margin-top: rem-calc(-32);
                margin-left: rem-calc(-75);

                @media screen and (min-width: 360px) {
                    margin-left: rem-calc(-85);
                }

                @include media-breakpoint-up(lg) {
                    width: 100%;
                    margin-top: 0;
                    margin-left: 0;
                }
            }

            &.calculator-red__img--morepayments {
                img {
                    @media screen and (min-width: 360px) {
                        margin-left: rem-calc(-90);
                    }
                }
            }
        }

        &__title {
            color: $cc-green;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }

            &.calculator-red__title--morepayments {
                h1 {
                    font-size: rem-calc(30);
                    @include media-breakpoint-up(md) {
                        font-size: rem-calc(62);
                    }
                }
            }
        }

        &__label {
            z-index: 2;
            font-size: rem-calc(14);
            @include media-breakpoint-up(md) {
                font-size: rem-calc(22);
            }
        }

        &__desc {
            font-size: rem-calc(14);
            @media screen and (min-width: 812px) {
                font-size: rem-calc(22);
            }
        }
    }

    .text--sale {
        color: $cc-green;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: rem-calc(70);
    }

    h1 {
        font-size: rem-calc(62);
        line-height: rem-calc(44);
        font-weight: 600;
        @media screen and (min-width: 812px) {
            font-size: rem-calc(120);
        }
    }

    h2 {
        margin-bottom: rem-calc(40);
        color: $white;
    }

    p {
        font-size: rem-calc(19);
        text-transform: uppercase;
        font-weight: bold;
    }

    .button {
        &.primary {
            color: white;
        }

        &.main {
            margin: rem-calc(70) auto rem-calc(30) auto;

            @include media-breakpoint-up(md) {
                margin: 0 auto rem-calc(70) auto;
            }

            @include media-breakpoint-up(lg) {
                margin: 0 auto rem-calc(30) auto;
            }
        }
    }

    .stripes {
        @include media-breakpoint-up(lg) {
            &:before,
            &:after {
                display: block;
                content: '';
                position: absolute;
                margin-top: -73px;
                width: 0;
                height: 0;
                border-style: solid;
                z-index: 1;

                @media screen and (min-width: 2000px) {
                    margin-top: -173px;
                }
            }

            &:before {
                right: 50%;
                border-width: 100px 0 100px 1000px;
                border-color: transparent transparent transparent $darker-red;

                @media screen and (min-width: 2000px) {
                    border-width: 200px 0 200px 2000px;
                }
            }

            &:after {
                left: 50%;
                border-width: 100px 1000px 100px 0;
                border-color: transparent $darker-red transparent transparent;

                @media screen and (min-width: 2000px) {
                    border-width: 200px 2000px 200px 0;
                }
            }
        }
    }

    table {
        max-width: rem-calc(800);
        margin: 0 auto;
        position: relative;
        z-index: 2;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(20);
        }

        @include media-breakpoint-up(lg) {
            max-width: rem-calc(600);
            font-size: rem-calc(14);
        }

        td,
        th {
            text-transform: uppercase;
            padding: rem-calc(5 10);

            @include media-breakpoint-up(md) {
                padding: rem-calc(7 33);
            }

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }

        tr:nth-of-type(2n) {
            td,
            th {
                background: $darker-red;
            }
        }
    }

    & > .calculator--top {
        h1 {
            text-transform: uppercase;
            margin-bottom: rem-calc(10);

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }

        h2 {
            font-weight: 300;
            font-size: rem-calc(21);

            @include media-breakpoint-up(md) {
                font-size: rem-calc(32);
            }
        }
    }
}

#blog-slider {
    h2 {
        margin-bottom: rem-calc(42);

        @include media-breakpoint-down(md) {
            margin-bottom: rem-calc(16);
        }
    }
}

#blog-slider-first {
    padding: rem-calc(51) 0 rem-calc(20);

    @include media-breakpoint-up(md) {
        padding: rem-calc(51) 0;
    }
}

#slider-carrier {
    position: relative;
}

#map-canvas {
    position: relative;
    width: 100%;
    height: 100%;
}

#faq {
    padding-bottom: rem-calc(80);
    background: $light-gray;
    font-weight: 600;
    position: relative;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(18);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(16);
    }

    h3 {
        margin-bottom: rem-calc(15);
        font-size: rem-calc(18);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(23);
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(18);
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        @include media-breakpoint-up(md) {
            margin: rem-calc(0 0 0 -20);
        }

        li {
            list-style: none;

            ul {
                margin: 0;
            }

            > a {
                color: $cc-green;
                text-decoration: underline;
            }

            > a.li-href {
                text-decoration: none;
                display: block;
                font-weight: 600;
                color: black;
                line-height: 1.2;
                padding: rem-calc(7 0);

                @include media-breakpoint-up(md) {
                    padding: rem-calc(12 20);
                }

                &:hover {
                    background: $white;
                }
            }

            &[aria-expanded='true'] {
                background: $white;
            }

            .is-submenu-item {
                font-weight: normal;
                line-height: 1.4;
                background: $white;

                @include media-breakpoint-up(md) {
                    padding: rem-calc(6 20 20 20);
                }
            }
        }
    }

    .arrow {
        width: rem-calc(100);
        height: rem-calc(100);
        background: $white url('../../images/ico-arrow-up.svg') 50% 50% no-repeat;
        background-size: auto rem-calc(35);
        position: absolute;
        bottom: rem-calc(-50);
        left: 50%;
        margin-left: rem-calc(-50);
        border-radius: 50%;
    }
}

#slider-carrier-orange {
    position: relative;

    h2 {
        margin-bottom: rem-calc(20);
    }

    &:before {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rem-calc(24 25 0 25);
        //border-color: $white transparent transparent transparent;
        position: absolute;
        left: 50%;
        top: -1px;
        margin-left: rem-calc(-25);
    }
}

#section-carrier-contact {
    position: relative;

    &:before {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rem-calc(24 25 0 25);
        //border-color: $white transparent transparent transparent;
        position: absolute;
        left: 50%;
        top: -1px;
        margin-left: rem-calc(-25);
    }
}

#help,
#newsletter {
    position: relative;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
        font-size: $global-font-size + rem-calc(4);
    }

    h2 {
        margin-bottom: rem-calc(20);
    }

    .phone__wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: rem-calc(10) 0 rem-calc(20);
    }

    &:before {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rem-calc(24 25 0 25);
        position: absolute;
        left: 50%;
        top: 0;
        margin-left: rem-calc(-25);
    }

    .phone-label {
        margin: 0;
        max-width: 70%;

        @include media-breakpoint-down(md) {
            max-width: 70%;
        }
    }

    .phone {
        display: flex;
        background-color: #e48c12;
        font-weight: bold;
        text-decoration: none;
        color: $white;
        position: relative;

        .fa-spinner {
            position: absolute !important;
            margin: 0 !important;
            right: 15px;
            top: 25%;
            color: #82ae33;

            :before {
                color: #82ae33;
            }
        }

        @include media-breakpoint-up(lg) {
            margin: 0;
        }

        .help-me-prefix {
            width: 20%;
            display: grid;
            place-items: center;
            background-color: #e48c12;
            color: $white;
        }

        input {
            display: inline-block;
            height: rem-calc(52);
            border-radius: 0;
            color: $medium-gray;
            font-weight: bold;
            text-align: left;
        }
    }

    .larger {
        vertical-align: middle;

        @include media-breakpoint-between(md, lg) {
            font-size: rem-calc(23);
        }
    }

    .button {
        vertical-align: middle;
        margin: rem-calc(20) auto rem-calc(5);

        @include media-breakpoint-up(lg) {
            margin-top: rem-calc(5);
        }

        @include media-breakpoint-down(md) {
            font-size: 14px !important;
        }
    }

    .arrow {
        width: rem-calc(100);
        height: rem-calc(100);
        background: $white url('../../images/ico-arrow-up.svg') 50% 50% no-repeat;
        background-size: auto rem-calc(35);
        position: absolute;
        bottom: rem-calc(-50);
        left: 50%;
        margin-left: rem-calc(-50);
        border-radius: 50%;
    }

    .form {
        background-color: transparent;
        border: none;
        padding: 0;
    }
}

#article-list {
    padding: 0 0 rem-calc(40) 0;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(40) 0 rem-calc(50) 0;
    }
}

#intro {
    padding-top: rem-calc(45);
    padding-bottom: rem-calc(30);
    background: $cc-green;
    color: $white;
    width: 100%;
    overflow: hidden;
    position: relative;

    // Every form Intro
    &.form-intro {
        padding-top: rem-calc(28);
        padding-bottom: rem-calc(18);

        p {
            font-size: rem-calc(16);

            a {
                color: $dark-orange;
                font-weight: bold;
                text-decoration: underline;
            }
        }

        @include media-breakpoint-up(md) {
            padding-top: rem-calc(40);
            padding-bottom: rem-calc(30);
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: rem-calc(60);
        padding-bottom: rem-calc(70);
    }

    h1 {
        text-transform: uppercase;
        margin-bottom: rem-calc(10);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(45);
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        & + p {
            font-weight: 300;
            font-size: rem-calc(21);

            @include media-breakpoint-up(md) {
                font-size: rem-calc(32);
            }
        }
    }

    h2 {
        margin-top: rem-calc(20);
        color: white;
        text-transform: none;
        font-size: rem-calc(24);

        span {
            color: $dark-purple;
        }

        @include media-breakpoint-up(md) {
            font-size: rem-calc(38);
        }
    }

    .button {
        &.main {
            margin-bottom: rem-calc(50);
            margin-left: auto;
            margin-right: auto;
            @include media-breakpoint-up(md) {
                margin-bottom: rem-calc(70);
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: rem-calc(30);
            }
        }
    }

    .stripes {
        @include media-breakpoint-up(lg) {
            &:before,
            &:after {
                display: block;
                content: '';
                position: absolute;
                margin-top: -73px;
                width: 0;
                height: 0;
                border-style: solid;
                z-index: 1;

                @media screen and (min-width: 2000px) {
                    margin-top: -173px;
                }
            }

            &:before {
                right: 50%;
                border-width: 100px 0 100px 1000px;
                border-color: transparent transparent transparent $cc-green-darker;

                @media screen and (min-width: 2000px) {
                    border-width: 200px 0 200px 2000px;
                }
            }

            &:after {
                left: 50%;
                border-width: 100px 1000px 100px 0;
                border-color: transparent $cc-green-darker transparent transparent;

                @media screen and (min-width: 2000px) {
                    border-width: 200px 2000px 200px 0;
                }
            }
        }
    }
}

#tree {
    h2 {
        color: $white;
    }
}

#links {
    color: $medium-gray;
    padding: rem-calc(50) 0 rem-calc(20) 0;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(60) 0 rem-calc(30) 0;
    }

    ul {
        margin: rem-calc(10) 0 rem-calc(30) 0;
        padding: 0;

        li {
            list-style: none;
            margin: 0;
            padding: 0 0 10px;
        }
    }
}

#ratings {
    h2 {
        color: $cc-green;
    }

    &.section-white {
        .btn-default {
            background-color: $light-gray;
        }
    }

    .ratings {
        margin-top: rem-calc(30);

        .percents {
            > div {
                display: inline-block;
                font-weight: bold;
            }

            .left {
                color: $lighter-black;
                font-size: rem-calc(65);
            }

            .right {
                margin-left: rem-calc(5);
                color: $cc-green;
                text-transform: uppercase;
                font-size: rem-calc(25);
                line-height: rem-calc(30);
            }
        }

        .stars {
            > div {
                display: inline-block;
            }

            .left {
                i.fa {
                    font-size: rem-calc(45);
                    color: #ffde00;
                }
            }

            .right {
                margin-left: rem-calc(5);
                color: $lighter-black;
                font-size: rem-calc(18);
                line-height: rem-calc(25);
            }
        }

        .texts {
            padding-top: rem-calc(10);
            min-height: rem-calc(140);
            color: $lighter-black;

            .header {
                font-weight: bold;
                line-height: rem-calc(40);

                .quoute-mark {
                    vertical-align: middle;
                    font-size: rem-calc(60);
                }

                .name,
                .age {
                    font-style: italic;
                }
            }

            .text {
                font-style: italic;
            }
        }

        .quoute-slider {
            margin-top: rem-calc(30);
            text-align: right;

            button {
                font-weight: 600;
                color: gray;
                text-transform: uppercase;
                padding: 10px 16px;
                border: none;
                border-radius: 8px;
            }

            .prev {
                background-image: url('../../images/ico-slider-left-gray.png');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto rem-calc(20);
            }

            .next {
                padding-right: 40px;
                background-image: url('../../images/ico-slider-right-gray.png');
                background-position: 93% center;
                background-repeat: no-repeat;
                background-size: auto rem-calc(20);
            }
        }
    }
}

#steps {
    font-size: rem-calc(16);

    @include media-breakpoint-up(md) {
        font-size: rem-calc(20);
    }

    @include media-breakpoint-up(lg) {
        font-size: $global-font-size + rem-calc(4);
    }

    h2 {
        margin-bottom: rem-calc(20);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(50);
        }
    }

    p {
        margin-bottom: rem-calc(7);
    }

    .container {
        max-width: rem-calc(1060);
    }

    .ico-steps {
        padding-left: rem-calc(100);
        background-repeat: no-repeat;
        background-size: rem-calc(60) auto;
        background-position: rem-calc(10) 0;

        @include media-breakpoint-up(md) {
            padding-left: rem-calc(150);
            background-position: rem-calc(40) 0;
        }

        &.ico-1 {
            background-image: url('../../images/ico-steps-1-purple.png');

            @include media-breakpoint-up(md) {
                background-size: rem-calc(68) auto;
            }
        }

        &.ico-2 {
            background-image: url('../../images/ico-steps-2-purple.png');

            @include media-breakpoint-up(md) {
                background-size: rem-calc(82) auto;
            }
        }

        &.ico-3 {
            background-image: url('../../images/ico-steps-3-purple.png');

            @include media-breakpoint-up(md) {
                background-size: rem-calc(76) auto;
            }
        }

        &.ico-4 {
            background-image: url('../../images/ico-steps-4-purple.png');

            @include media-breakpoint-up(md) {
                background-size: rem-calc(69) auto;
            }
        }
    }

    .button.main {
        margin: rem-calc(30) auto 0;
    }
}

#waiting {
    h2 {
        margin-bottom: rem-calc(30);
    }

    strong.larger {
        text-transform: uppercase;
    }

    a.j-link-with-anchor {
        margin: rem-calc(20) auto;
    }
}

#when-small {
    margin-top: 2rem;
    padding-bottom: 3rem;

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }
    @extend #when;

    .time {
        margin-bottom: rem-calc(5) !important;

        tr td {
            padding: 0.7rem 0 !important;

            @include media-breakpoint-up(md) {
                padding: 0 !important;
            }

            &:first-child {
                max-width: 12rem;
            }
        }
    }

    h3 {
        font-size: rem-calc(22) !important;
        margin: rem-calc(25) 0 !important;

        @include media-breakpoint-up(md) {
            margin: rem-calc(40) 0 rem-calc(20) !important;
        }
    }
}

#google-map {
    padding-top: 0;

    #map-canvas {
        height: 615px;
        border-bottom: 2px solid $cc-green;
    }

    //Map infowindow
    .gm-style-iw {
        h1 {
            display: block;
            color: $cc-green;
            font-size: 13px;
        }

        span {
            display: block;
            color: black;
            font-size: 13px;
        }

        p {
            margin: 10px 0 0 0;

            a {
                text-decoration: underline;
            }

            a:after {
                font: normal normal normal 14px/1 FontAwesome;
                font-weight: 900;
                content: '\f277';
                margin-left: 10px;
                font-size: 20px;
            }
        }
    }
}

#when {
    color: black;

    h2 {
        color: $cc-green;
    }

    h3 {
        color: black;
        text-transform: none;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(32);
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(27);
        }
    }

    .clock .space {
        span {
            &.first,
            &.second {
                background-color: $cc-green;
            }
        }
    }

    & .time table {
        width: 100%;
        margin-bottom: 1rem;
        border-collapse: collapse;
    }

    .time {
        margin-top: rem-calc(30);
        max-width: rem-calc(780);
        margin-left: auto;
        margin-right: auto;
        background: url('../../images/ico-time-gray.png') center center no-repeat;

        &--nobg {
            background: none;
        }

        @include media-breakpoint-up(lg) {
            margin-top: rem-calc(50);
            margin-bottom: rem-calc(70);
        }

        tbody {
            width: 100%;
        }

        tr {
            @include media-breakpoint-up(md) {
                display: flex;
            }

            td {
                border: none;
                padding: rem-calc(10) 0;
                vertical-align: middle;
                text-align: left;
            }

            td:last-child {
                color: $black;
                font-weight: bold;
                text-transform: uppercase;
                text-align: right;
            }

            .line {
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                    flex-grow: 1;
                }

                > div {
                    margin: 0 rem-calc(15);
                    height: rem-calc(15);
                    border-bottom: 1px solid #cccccc;
                }
            }
        }
    }

    .clock {
        margin-bottom: rem-calc(30);
    }

    .j-link-with-anchor {
        margin: rem-calc(20) auto 0 auto;
    }
}

#form-change-passwords {
    .inputs {
        label {
            //Form errors
            ul {
                display: none;
            }
        }

        label.required {
            margin-bottom: 0;
        }

        input[type='password'] {
            margin-bottom: rem-calc(20);
        }
    }
}

.top-up {
    margin-top: 30px;
    margin-bottom: 50px;

    .slider {
        margin: auto !important;
    }
}

.topup-h2-recap {
    font-size: 1.8rem !important;
    line-height: 2.5rem;
    text-transform: uppercase;
    color: black !important;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
}

#banks_actualize_spinner {
    i {
        color: $cc-green;
    }
}

.embedsocial-reviews-iframe {
    min-height: rem-calc(560);
    @include media-breakpoint-up(md) {
        min-height: auto;
    }
}

.gdpr-document {
    margin-top: rem-calc(20);
}
