.input {
    &--newsletter {
        input::placeholder {
            text-align: left;
        }

        input {
            text-align: left !important;
            padding-left: 1rem !important;
            width: 100% !important;
            display: inline-block;
            height: rem-calc(52);
            margin: 0;
            border: none;
            background: $light-gray;
            border-radius: 0;
            color: $medium-gray;
            font-weight: bold;

            &.is-invalid-input {
                color: $red;
                border-color: $red;
            }
        }
    }

    &-search {
        input {
            background-color: $cc-green-soft;
            margin: 0;
            padding: 1rem;
            border: 0;
            border-radius: 0;
            &,
            &::placeholder {
                color: $lighter-gray;
            }
            &[type='text'] {
                padding-right: 3rem;
            }
            &:focus {
                background-color: $cc-green-soft;
                border: 0;
            }
        }
    }
}
