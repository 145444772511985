.rw-input {
    font-size: 1.2rem;
    padding: 0.71429rem 2.14286rem 0.71429rem 0.85714rem;
    height: auto;
    background: #f2f2f2;
    color: #585a5d;
    border: none;
    border-radius: 0;
    border-bottom: 6px solid #e3e3e3;
    margin: 0;
    margin-bottom: 15px;

    @include media-breakpoint-down(sm) {
        font-size: 0.9rem;
    }
}
