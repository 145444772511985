.text {
    line-height: 1.5rem;
    &--primary {
        color: $dark-purple;
        a {
            color: $dark-purple;
        }
    }
    &--secondary {
        color: $dark-orange;
        a {
            color: $dark-orange;
        }
    }
    &--third {
        color: $cc-green;
        a {
            color: $cc-green;
        }
    }
    &--black {
        color: $lighter-black !important;
        a {
            color: $lighter-black !important;
        }
    }
    &--red {
        color: $dark-red;
        a {
            color: $dark-red;
        }
    }
    &--white {
        color: $light-gray;
        a {
            color: $light-gray;
        }
    }
    &--sale {
        color: red;
        margin-right: rem-calc(15);
        @include media-breakpoint-down(md) {
            margin-right: rem-calc(2);
        }
        a {
            color: red;
        }
    }
    &--strike {
        position: relative;
        display: inline-block;
        &:before {
            content: '';
            border-bottom: rem-calc(2) solid red;
            width: 100%;
            position: absolute;
            right: 0;
            top: 40%;
        }
    }
}
