.crement {
    cursor: pointer;
    position: absolute;
    top: -10px;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid $dark-purple;
    background-color: white;
    text-align: center;
    font-size: 19px;
    color: $dark-purple;
    outline: none;
    user-select: none;

    &:hover {
        background-color: darken(white, 5%);
    }

    &--in {
        right: 0;
    }
}
