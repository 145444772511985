@use 'sass:color';

@mixin prefix {
    height: 44px;
    width: 55px;
    position: absolute;
    top: 0;
    left: 0;
    padding: rem-calc(10 0);
    background: $form-gray;
    color: $dark-gray;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
}

.cell-smaller-padding-left {
    padding-left: rem-calc(5) !important;
}
.cell-smaller-padding-right {
    padding-right: rem-calc(5) !important;
}

.steps {
    position: relative;
    z-index: 2;
    text-align: left;

    > .col {
        display: inline-block; //Android old browser fix
    }

    .step {
        border-radius: rem-calc(10 10 0 0);
        height: rem-calc(50);
        line-height: rem-calc(55);
        color: $white;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        background-color: $cc-green-soft;
        background-position: 10px center;
        background-repeat: no-repeat;
        background-size: 25px;

        @include media-breakpoint-up(md) {
            text-align: left;
            padding-left: rem-calc(55);
        }

        &.step-1 {
            @include media-breakpoint-up(md) {
                background-image: url('../../images/ico-step-person.png');
                padding-left: 3.5rem;
            }

            &.active {
                @include media-breakpoint-up(md) {
                    background-image: url('../../images/ico-step-person-purple.png');
                }
            }
        }

        &.step-2 {
            background-size: 30px;

            @include media-breakpoint-up(md) {
                background-image: url('../../images/ico-step-card.png');
                padding-left: 3.5rem;
            }

            &.active {
                @include media-breakpoint-up(md) {
                    background-image: url('../../images/ico-step-card-purple.png');
                }
            }

            .progress-bar {
                width: 45%;
            }
        }

        &.step-3 {
            background-size: 30px;

            @include media-breakpoint-up(md) {
                background-image: url('../../images/ico-step-money.png');
                padding-left: 3.5rem;
            }

            &.active {
                @include media-breakpoint-up(md) {
                    background-image: url('../../images/ico-step-money-purple.png');
                }
            }
        }

        .step-text {
            color: white;
            font-size: rem-calc(14);

            @include media-breakpoint-up(md) {
                font-size: rem-calc(18);
            }
        }

        &.active {
            height: rem-calc(51);
            margin-bottom: -1px;
            background-color: white;
            border-top: 1px solid $form-gray;
            border-right: 1px solid $form-gray;
            border-left: 1px solid $form-gray;

            > span {
                color: $dark-purple;
            }
        }

        .progress-bar {
            display: none;
            margin-left: rem-calc(25);
            width: 55%;
            height: rem-calc(12);
            border: 1px solid $form-gray;
            border-radius: rem-calc(5);
            background-color: $light-gray;
            overflow: hidden;

            .progress {
                height: 100%;
                width: 50%;
                background-color: $dark-orange;
                border-radius: rem-calc(5) 0 0 rem-calc(5);
            }

            @include media-breakpoint-up(lg) {
                display: inline-block;
            }
        }
    }
}

.form {
    z-index: 1;
    background-color: white;
    border: 1px solid $form-gray;
    border-radius: rem-calc(0 0 15 15);
    margin-bottom: rem-calc(20);
    padding-left: rem-calc(20);
    padding-right: rem-calc(20);
    position: relative;

    &.has-footer {
        border-radius: 0;
        margin-bottom: 0;
    }

    .back {
        display: inline-block;
        margin-top: rem-calc(20);
        text-transform: uppercase;
        font-weight: 600;

        @include media-breakpoint-up(lg) {
            position: absolute;
            left: rem-calc(35);
            bottom: rem-calc(15);
        }
    }

    .href-back {
        margin: rem-calc(20) 0;
        font-weight: 600;
    }

    .popover.info {
        display: inline-block;
        width: rem-calc(16);
        height: rem-calc(16);
        vertical-align: middle;
        margin: 0 0 0 rem-calc(10);
        background: url('../../images/ico-questionmark-green.png') center center no-repeat;
        background-size: 100% auto;
        position: relative;
        top: rem-calc(-3);
        cursor: pointer;
        outline: none;
        border: none;
    }

    .info-wrap {
        position: relative;

        .info {
            position: absolute;
            right: rem-calc(10);
            top: 50%;
            margin-top: rem-calc(-12);
        }
    }

    .with-opacity {
        opacity: 0.5;
    }

    h2 {
        margin: rem-calc(30) 0 rem-calc(20) 0;
        font-size: $global-font-size + rem-calc(4);
        color: $cc-green;
        font-weight: normal;

        &.registration-form__subheading {
            width: 100% !important;
            color: $dark-gray;
            font-size: 14px;
        }

        &.is-form-label {
            font-size: 13px;
            margin: rem-calc(30) 0 rem-calc(5) 0;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .inputs {
        max-width: rem-calc(660);
        margin: 0 auto;

        a.button {
            color: white;
        }

        &--paddings {
            padding: 4rem 0 2rem;
        }
    }

    label {
        text-transform: uppercase;
        text-align: left;
        color: $cc-green;
        font-size: rem-calc(12);
        margin-bottom: rem-calc(25);
        font-weight: 600;

        &:has(input[type='text']),
        &:has(input[type='email']),
        &:has(input[type='number']),
        &:has(input[type='tel']),
        &:has(select) {
            width: 100%;
        }

        .suffix-wrapper {
            display: flex;
            height: 50px;

            & > input {
                z-index: 2;

                & + .suffix {
                    transition: border-bottom-color 0.35s;
                }

                &.invalid + .suffix {
                    border-bottom: $form-input-border-bottom solid #c32d2c;
                }

                &:focus + .suffix {
                    border-bottom: $form-input-border-bottom solid #82ae33;
                }

                &.valid + .suffix {
                    border-bottom: $form-input-border-bottom solid #98c052;
                }
            }

            .suffix {
                height: 100%;
                width: 55px;
                padding: 10px 0;
                background: $form-gray;
                color: $dark-gray;
                font-size: 16px;
                text-align: center;
                font-weight: bold;
                text-transform: none;
                z-index: 1;
            }
        }

        input {
            padding: rem-calc(10 30 10 12);
            height: auto;
            background: $light-gray;
            color: $dark-gray;
            margin: 0;
            border: none;
            border-bottom: $form-input-border-bottom solid $form-gray;
            border-radius: 0;
            font-size: 16px;
            text-align: left;
            font-weight: 600;
            width: 100%;
            box-shadow: inset 0 1px 2px rgba(55, 41, 43, 0.1);
            transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
            outline: none;
            line-height: 1.5;

            &:focus[type='text'],
            &:focus[type='email'],
            &:focus[type='number'],
            &:focus[type='tel'] {
                box-shadow: 0 0 5px #8c9199;
                background-color: #fff;
            }

            &[type='checkbox'] {
                width: 0;
            }

            &:focus,
            &:active {
                border: none;
                border-bottom: 6px solid $cc-green;
            }

            &:read-only {
                cursor: text;
            }

            &.valid {
                border: none;
                border-bottom: 6px solid $cc-green-soft;
            }
            &.invalid {
                &:not(:focus) {
                    background-color: #f9eaea;
                    border-color: #c32d2c;

                    /*::placeholder {
                        color: #c32d2c;
                    }*/
                }
            }

            &.sms-pin {
                font-size: 32px;
                text-align: center;
            }

            &:disabled {
                background-color: #f2f2f2;
                cursor: not-allowed;
                color: #b5bac2;

                & + span {
                    color: #b5bac2;
                }
            }

            &[readonly] {
                background-color: #f2f2f2;

                & + span:hover {
                    background-color: #f2f2f2;
                }
            }
        }

        select {
            padding: rem-calc(10) rem-calc(30) rem-calc(10) rem-calc(12);
            height: auto;
            background: $light-gray;
            color: $placeholder-gray;
            margin: 0;
            border: none;
            border-bottom: 6px solid $form-gray;
            border-radius: 0;
            font-size: 16px;
            font-weight: 600;

            &:focus {
                box-shadow: 0 0 5px #8c9199;
                background-color: #fff;
            }

            &:focus,
            &:active {
                border: none;
                border-bottom: 6px solid $cc-green;
            }

            &:disabled {
                border-bottom: 6px solid $form-gray;
            }

            &.valid {
                border: none;
                border-bottom: 6px solid $cc-green-soft;
            }
        }

        select.selected {
            color: $dark-gray;
        }

        .form-error {
            display: none;
            background: $alert-color;
            color: $white;
            text-transform: uppercase;
            text-align: center;
            padding: rem-calc(3);
            margin-top: 0;
            margin-bottom: 0;
        }

        .form-success {
            display: none;
            background: #98c052;
            color: $white;
            text-transform: uppercase;
            text-align: center;
            padding: rem-calc(3);
            margin-top: 0;
            margin-bottom: 0;
        }

        .prefix-wrap {
            position: relative;

            input {
                padding-left: rem-calc(70);
            }

            .prefix {
                @include prefix;
            }
        }
    }

    .validated {
        input {
            border: none;
            border-bottom: 6px solid $white;
            background-image: url('../../images/ico-check.png');
            background-repeat: no-repeat;
            background-position: 97% 50%;
            background-size: auto 37%;
            color: $cc-green-soft;
        }

        .info {
            display: none;
        }
    }

    ::-webkit-input-placeholder {
        color: $placeholder-gray;
    }
    ::-moz-placeholder {
        color: $placeholder-gray;
    }
    :-ms-input-placeholder {
        color: $placeholder-gray;
    }
    ::-moz-placeholder {
        color: $placeholder-gray;
    }
    ::placeholder {
        color: $placeholder-gray;
    }

    .checkbox {
        margin: rem-calc(10) 0;
        text-align: center;

        &__text-left {
            text-align: left;
        }

        label {
            display: inline-block;
            font-size: rem-calc(12px);
            text-transform: none;
            padding-left: rem-calc(40);
            position: relative;
            line-height: 1.4;
            padding-top: rem-calc(4);
            margin-bottom: 0;
            color: $dark-gray;

            &.left {
                width: 100%;
            }

            span.checked:after {
                display: block;
                content: '';
                width: rem-calc(16);
                height: rem-calc(16);
                background: $cc-green;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: rem-calc(-8) 0 0 rem-calc(-8);
                border-radius: 50%;
            }

            input {
                position: absolute;
                left: 7px;
                z-index: -1;

                + span {
                    display: inline-block;
                    width: rem-calc(28);
                    height: rem-calc(28);
                    border: 2px solid $cc-green;
                    background-color: white;
                    vertical-align: middle;
                    position: absolute;
                    left: 0;
                    top: rem-calc(-3);
                }

                &:checked + span:after {
                    display: block;
                    content: '✓';
                    color: $cc-green;
                    text-align: center;
                    font-size: rem-calc(16);
                    width: rem-calc(16);
                    height: rem-calc(16);
                    line-height: rem-calc(16);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .sources {
        width: 100%;
        padding: 0 rem-calc(3);
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;

        + label:has(.form-error) {
            width: 100%;
            padding: 0 rem-calc(3);
        }

        > label {
            position: relative;
            display: inline-block;
            flex-grow: 1;
            text-transform: uppercase;
            margin: 0;

            span {
                display: block;
                padding: rem-calc(10);
                border: 1px solid $white;
                margin-right: -1px;
                text-align: center;
                background-color: $light-gray;
                cursor: pointer;
                box-sizing: border-box;
                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, current supported by Chrome and Opera */

                &.children {
                    @include media-breakpoint-down(md) {
                        padding: rem-calc(10) rem-calc(20);
                    }
                }

                &:hover {
                    background-color: $form-gray;
                }
            }

            input {
                position: absolute;
                //top: 15px;
                //left: 55px;
                z-index: -1;

                &[disabled] {
                    & + span,
                    & + span + span {
                        cursor: not-allowed;
                    }
                }
            }

            input:checked {
                & + span,
                & + span + span {
                    color: white;
                    background-color: $dark-purple;
                }

                &[disabled] {
                    & + span,
                    & + span + span {
                        cursor: not-allowed;
                        color: $dark-gray;
                        background-color: $form-gray;
                    }
                }
            }
        }

        // Disabled in /klient/pujcka
        &.disabled {
            > label {
                span {
                    color: $dark-gray;
                    font-weight: 700;
                }

                input:checked + span {
                    background-color: $form-gray;
                    color: $dark-gray;
                }
            }
        }
    }

    .button {
        width: 100%;
        margin: rem-calc(20) auto rem-calc(20) auto;

        span {
            color: white;
        }

        @include media-breakpoint-up(md) {
            width: auto;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(30);
            margin-top: rem-calc(30);
        }
    }

    .link {
        display: inline-block;
        margin: rem-calc(10) 0 rem-calc(40) 0;
        text-decoration: underline;
    }

    .documents-menu-vertical {
        max-width: rem-calc(960);
        margin: rem-calc(10) auto;

        .menu-item {
            margin-bottom: rem-calc(5);

            .header {
                clear: both;
                background-color: $lighter-gray;
                background-image: url('../../images/ico-uncheck_light-gray.png');
                background-position: rem-calc(15) center;
                background-repeat: no-repeat;
                background-size: rem-calc(23);
                height: rem-calc(55);
                border-left: rem-calc(4) solid $form-gray;
                line-height: rem-calc(25);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: rem-calc(60);
                color: black;
                text-align: left;

                @include media-breakpoint-up(xs) {
                    line-height: rem-calc(62);
                }

                > .text {
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: rem-calc(14);

                    @include media-breakpoint-up(md) {
                        font-size: rem-calc(18);
                    }
                }

                > .description {
                    display: none;
                }

                &.finished,
                &.active {
                    > .description {
                        display: none;
                        float: right;
                        margin-right: rem-calc(20);

                        @include media-breakpoint-up(lg) {
                            display: inline-block;
                        }
                    }
                }

                &.finished {
                    background-image: url('../../images/ico-check.png');
                    border-left: rem-calc(4) solid $cc-green;

                    > .text {
                        color: $cc-green;
                    }
                }

                &.active {
                    background-color: white;

                    > .description {
                        font-weight: bold;
                    }
                }
            }

            .content {
                padding: rem-calc(10) rem-calc(20) rem-calc(10) rem-calc(20);
                border-left: rem-calc(4) solid $form-gray;

                h3 {
                    margin: 0 auto rem_calc(20) auto;
                    text-transform: none;
                    color: $dark-purple;
                    font-size: rem_calc(16);
                }

                .scrollable-text {
                    margin: rem-calc(10) 0;
                    overflow: hidden;
                    text-align: left;
                    border-color: $lighter-gray;
                    border-radius: $global-radius;
                    color: black;
                    padding: rem_calc(10);
                    max-height: rem-calc(200);
                    font-size: rem_calc(14);

                    .text {
                        max-height: rem-calc(160);
                        overflow: auto;

                        &::-webkit-scrollbar {
                            width: 15px;
                            border-radius: 10px;
                        }

                        &::-webkit-scrollbar-track {
                            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                            border-radius: 10px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $primary-color;
                            outline: 1px solid slategrey;
                        }
                    }
                }

                p {
                    font-size: rem-calc(17);
                    color: black;
                }

                p.purple-text {
                    margin: 0;
                    font-weight: 500;
                    font-size: rem-calc(14);
                    color: $dark-purple;
                }

                .bg-arrow {
                    background-image: url('../../images/down-pointing-curved-arrow.png');
                    background-position: right bottom;
                    background-repeat: no-repeat;
                    background-size: rem-calc(70);
                }

                .documents-arrow {
                    background-image: url('../../images/down-pointing-curved-arrow.png');
                    background-position: 2rem center;
                    background-repeat: no-repeat;
                    background-size: rem-calc(70);

                    @include media-breakpoint-up(lg) {
                        background-position: left center;
                    }
                }

                .floating-text {
                    margin-top: rem-calc(20);
                    text-align: left;

                    p {
                        font-size: rem-calc(14);
                        color: $dark-purple;
                        font-weight: 500;
                    }
                }

                .modal-hrefs {
                    font-weight: 500;

                    a {
                        color: $dark-purple;
                        text-decoration: underline;
                        font-size: rem-calc(14);
                    }
                }

                .agreements {
                    margin-top: rem-calc(40);

                    .checkbox {
                        margin-bottom: rem-calc(30);
                        text-align: left;
                    }

                    label {
                        padding-top: 0;
                        font-weight: 500;
                        font-size: rem-calc(14);

                        span {
                            font-weight: 600;
                        }
                    }

                    a {
                        color: black;
                        font-weight: normal;
                        text-decoration: underline;
                    }
                }

                .j-link-with-anchor {
                    margin-top: rem-calc(10);
                }

                .purple-href {
                    background-color: $dark-purple;

                    &:hover {
                        background-color: $darker-purple;
                    }
                }

                .frame {
                    margin-bottom: rem-calc(15);
                    text-align: left;
                    border-radius: $global-radius;
                    background-color: $lighter-gray;
                    background-position: rem-calc(25) center;
                    background-repeat: no-repeat;
                    background-size: rem-calc(60);

                    > span {
                        display: block;
                    }

                    .text {
                        color: $dark-purple;
                        font-size: rem-calc(16);
                        font-weight: bold;
                    }
                }
                .wallet-frame {
                    @extend .frame;

                    background-image: url('../../images/wallet-purple.png');
                    padding: rem-calc(40 50 50 120);
                    height: rem-calc(130);

                    .other-text {
                        display: none;
                        margin-top: rem-calc(5);
                        font-size: rem-calc(15);
                        @include media-breakpoint-up(md) {
                            display: block;
                        }
                    }
                }
                .paid-frame {
                    @extend .frame;

                    text-align: center;
                    padding: rem-calc(40 0 10 0);
                    margin-top: rem-calc(40);

                    .button {
                        margin-top: rem-calc(30);
                        background-color: $dark-purple;
                        color: $white;
                    }
                }

                .documents-upload--psd2 {
                    margin-bottom: rem-calc(15);
                    text-align: left;
                    border-radius: $global-radius;
                    background-color: $lighter-gray;
                    background-image: url('../../images/documents-psd2.svg');
                    background-position: rem-calc(25) center;
                    background-repeat: no-repeat;
                    background-size: rem-calc(60);
                    height: rem-calc(130);
                    padding: rem-calc(40 50 50 120);

                    > span {
                        display: block;
                    }

                    .text {
                        color: $dark-purple;
                        font-size: rem-calc(16);
                        font-weight: bold;
                    }

                    .other-text {
                        display: none;
                        margin-top: rem-calc(5);
                        font-size: rem-calc(15);
                        @include media-breakpoint-up(md) {
                            display: block;
                        }
                    }
                }
                .documents-upload--file {
                    margin-bottom: rem-calc(15);
                    text-align: left;
                    border-radius: $global-radius;
                    background-color: $lighter-gray;
                    background-image: url('../../images/documents-file.svg');
                    background-position: rem-calc(25) center;
                    background-repeat: no-repeat;
                    background-size: rem-calc(60);
                    height: rem-calc(130);
                    padding: rem-calc(40 50 50 120);

                    > span {
                        display: block;
                    }

                    .text {
                        color: $dark-purple;
                        font-size: rem-calc(16);
                        font-weight: bold;
                    }

                    .other-text {
                        display: none;
                        margin-top: rem-calc(5);
                        font-size: rem-calc(15);
                        @include media-breakpoint-up(md) {
                            display: block;
                        }
                    }
                }

                .documents-upload--account {
                    margin-bottom: rem-calc(15);
                    text-align: left;
                    border-radius: $global-radius;
                    background-color: $lighter-gray;
                    background-image: url('../../images/documents-upload.svg');
                    background-position: rem-calc(25) center;
                    background-repeat: no-repeat;
                    background-size: rem-calc(60);
                    padding: rem-calc(40 50 0 120);

                    > span {
                        display: block;
                    }

                    .text {
                        color: $dark-purple;
                        font-size: rem-calc(16);
                        font-weight: bold;
                    }

                    .other-text {
                        display: none;
                        margin-top: rem-calc(5);
                        font-size: rem-calc(15);
                        @include media-breakpoint-up(md) {
                            display: block;
                        }
                    }
                }

                .documents-upload--wallet {
                    margin-bottom: rem-calc(15);
                    text-align: left;
                    border-radius: $global-radius;
                    background-color: $lighter-gray;
                    background-image: url('../../images/documents-wallet.svg');
                    background-position: rem-calc(25) center;
                    background-repeat: no-repeat;
                    background-size: rem-calc(60);
                    padding: rem-calc(40 50 0 120);

                    > span {
                        display: block;
                    }

                    .text {
                        color: $dark-purple;
                        font-size: rem-calc(16);
                        font-weight: bold;
                    }

                    .other-text {
                        display: none;
                        margin-top: rem-calc(5);
                        font-size: rem-calc(15);
                        @include media-breakpoint-up(md) {
                            display: block;
                        }
                    }
                }

                .documents-upload--upload {
                    margin-bottom: rem-calc(15);
                    text-align: left;
                    border-radius: $global-radius;
                    background-color: $lighter-gray;
                    background-position: rem-calc(25) center;
                    background-repeat: no-repeat;
                    background-size: rem-calc(60);

                    > span {
                        display: block;
                    }

                    .text {
                        color: $dark-purple;
                        font-size: rem-calc(16);
                        font-weight: bold;
                    }

                    .other-text {
                        display: none;
                        margin-top: rem-calc(5);
                        font-size: rem-calc(15);
                        @include media-breakpoint-up(md) {
                            display: block;
                        }
                    }
                    i {
                        font-size: 5rem;
                        font-weight: normal;
                    }
                }

                .document-password {
                    height: 1.75rem;
                    margin: 0 auto;

                    &-label {
                        font-size: 11px;
                        margin-bottom: 0;
                    }

                    &-alert {
                        font-size: 11px;
                        margin-top: 10px;
                        margin-bottom: 0;

                        > .alert {
                            padding: 10px !important;
                        }

                        & strong {
                            line-height: 21px;
                        }
                    }
                }

                .documents-upload--front__text,
                .documents-upload--back__text {
                    z-index: 1;
                    cursor: pointer;
                    pointer-events: none;
                }

                .documents-upload--front__text.hide,
                .documents-upload--back__text.hide {
                    display: none !important;
                }
                .documents-upload--front__text.active,
                .documents-upload--back__text.active {
                    display: flex;
                }

                .documents-howto {
                    ol {
                        list-style: none;
                        counter-reset: item;
                        margin-left: 0;
                    }
                    li {
                        counter-increment: item;
                        margin-bottom: 5px;
                        text-align: left;
                    }
                    li:before {
                        margin-right: 10px;
                        content: counter(item) '.';
                        border-radius: 100%;
                        color: $cc-green;
                        width: 1.2em;
                        font-weight: bold;
                        text-align: center;
                        display: inline-block;
                    }
                }

                .account-info {
                    margin-top: rem_calc(35);

                    label {
                        display: inline;
                        font-size: rem-calc(16);
                        line-height: rem-calc(13);

                        span {
                            font-weight: normal;
                            text-transform: none;
                            font-size: rem-calc(13);
                        }
                    }
                    input {
                        text-align: center;
                    }
                }

                #bankpayment {
                    margin-top: rem-calc(30);

                    p {
                        margin-top: rem-calc(15);
                        font-size: rem-calc(14);
                    }
                }
                #qrpayment {
                    img {
                        border: 3px solid $form-gray;
                    }
                }
                .confirmed-account-title {
                    font-size: 1.5rem;
                }
            } // ! content

            .content.confirmed-account-section {
                padding-top: rem-calc(50);
            }

            .content--documents {
                display: block;
                border-left: 0;
                @include media-breakpoint-up(md) {
                    border-left: 0.28571rem solid #e3e3e3;
                }
            }
        } // ! menu-item
    } // ! documents-menu-vertical

    .rollable-customer-info {
        max-width: 850px;
        margin: rem-calc(40) auto rem-calc(40) auto;
        border: 3px solid $form-gray;

        h3,
        h4 {
            margin: rem-calc(10) 0;
            text-align: left;
            text-transform: none;
            color: $dark-gray;
            font-size: rem-calc(16);
        }
        h4 {
            font-size: rem-calc(14);
        }

        .header {
            cursor: pointer;
            position: relative;
            padding: rem-calc(20) rem-calc(40);
            border-bottom: 3px solid $form-gray;

            > i {
                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;
                font-weight: normal;
                font-size: rem-calc(30);
                position: absolute;
                right: 30px;
                top: calc(50% - 15px);
            }
        }

        .body {
            padding: rem-calc(20) rem-calc(40);

            h4 {
                text-transform: uppercase;
                text-align: left;
                color: $dark-gray;
                font-size: $global-font-size;
                margin-bottom: 0;
                font-weight: 600;
            }

            p {
                font-weight: 600;
                letter-spacing: -0.25px;
            }
        }

        label {
            color: $dark-gray;
        }
    } // ! rollable-customer-info

    .loan-extension-pick {
        margin-top: rem-calc(40);

        .inputs {
            .option,
            .description {
                line-height: 50px;
            }

            label {
                cursor: pointer;
                margin-bottom: rem-calc(5);
                margin-top: rem-calc(20);
                height: 50px;
                line-height: 50px;
                border-radius: 5px;
                padding-left: 60px;
                background-color: $light-gray;
                background-image: url('../../images/ico-uncheck-gray.png');
                background-position: rem-calc(10) center;
                background-repeat: no-repeat;
                background-size: rem-calc(20);
                font-size: rem-calc(17);
                color: $dark-gray;
                font-weight: normal;
                text-transform: none;
                width: 100%;

                @include media-breakpoint-up(md) {
                    margin-top: 0;
                }

                &.active,
                &:hover {
                    background-color: $dark-purple;
                    background-image: url('../../images/ico-check-white.png');
                    font-weight: bold;
                    color: white;
                }

                input {
                    display: none;
                }
            }

            .description {
                line-height: normal;

                @include media-breakpoint-up(md) {
                    line-height: 50px;
                }

                .new-date {
                    padding-left: 10px;
                    font-weight: bold;
                    font-size: rem-calc(14);

                    &.active {
                        color: $dark-purple;
                    }
                }
            }
        }
    }
} // ! form

.form-contacs-footer {
    //box-shadow: 0 4px 6px 0 grey; //todo
    z-index: 1;
    background-color: white;
    border-right: 1px solid $form-gray;
    border-left: 1px solid $form-gray;
    border-bottom: 1px solid $form-gray;
    border-radius: rem-calc(0 0 15 15);
    margin-bottom: rem-calc(20);
    padding: rem-calc(40 20 20 20);
    position: relative;

    div.icon {
        margin-bottom: rem-calc(10);
        height: rem-calc(60);
        font-family: FontAwesome;
        font-size: rem-calc(45);

        &.mobile:before {
            font-size: rem-calc(50);
            content: '\f10b';
        }

        &.envelope:before {
            content: '\f003';
        }

        &.phone:before {
            content: '\f095';
        }
    }

    h4 {
        font-weight: 600;
    }

    h5 {
        margin-top: rem-calc(8);
        margin-bottom: rem-calc(8);
        font-weight: 600;
    }
} // ! form-contacs-footer

table.rpsn-table {
    .currency {
        text-transform: none !important;
    }

    max-width: rem-calc(800);
    margin: rem-calc(40) auto rem-calc(40) auto;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(20);
    }

    @include media-breakpoint-up(lg) {
        max-width: rem-calc(600);
        font-size: rem-calc(14);
    }

    .loan-installment-costs {
        &:has(.toggleCost) {
            cursor: pointer;
        }

        &__collection {
            text-align: left !important;

            & a {
                color: #81007f;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .costs {
        td:first-child {
            padding-left: 35px;
            font-size: 12px;
        }
    }

    td.pl-55 {
        padding-left: 55px !important;
    }

    td,
    th {
        text-transform: uppercase;
        padding: rem-calc(5 10);

        @include media-breakpoint-up(md) {
            padding: rem-calc(7 15);
        }

        &:first-child {
            text-align: left;
        }

        &:last-child:not(.text-left) {
            text-align: right;
        }

        > a.cancel {
            font-weight: bold;
            color: $dark-gray;
            text-transform: none;
            text-decoration: underline;
        }
    }

    tr.smaller {
        td,
        th {
            font-size: 0.9rem;
            padding: rem-calc(5 33);
        }
    }

    tr:nth-of-type(2n + 1) {
        td,
        th {
            background: $light-gray;
        }
    }

    .red {
        color: $dark-red;
        font-weight: bold;
        text-transform: none;
    }
    .cc-green {
        text-transform: none;
        font-weight: bold;
        color: $cc-green;
    }
}

.loan-in-progress {
    z-index: 1;
    background-color: white;
    border: 1px solid $form-gray;
    border-radius: rem-calc(0 0 15 15);
    margin-bottom: rem-calc(20);
    position: relative;

    .top {
        padding: rem-calc(30 0 0 0);

        @include media-breakpoint-up(lg) {
            padding: rem-calc(30 0 15 0);
        }

        span {
            font-weight: bold;
            font-size: rem-calc(17);
        }
    }

    .bottom {
        text-align: center;
        border-top: 3px solid $form-gray;
        height: 200px;
        padding: rem-calc(40 30);

        @include media-breakpoint-up(lg) {
            text-align: left;
            padding: rem-calc(40 30 40 250);
            background-image: url('../../images/phone.png');
            background-position: rem-calc(70) center;
            background-repeat: no-repeat;
            background-size: rem-calc(120);
        }

        h3,
        h4 {
            margin: rem-calc(12) 0;
            text-align: left;
            text-transform: none;
            color: $dark-gray;
            font-size: rem-calc(16);
        }
        h4 {
            font-size: rem-calc(14);
        }
        h3 {
            text-decoration: underline;
        }

        a,
        button {
            margin-top: rem-calc(10);
            color: white;
        }
    }
}

//------ BOOTSRAP -------
//Bootstrap Buttons
.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;

    &:hover {
        color: #333333;
        background-color: #e6e6e6;
        border-color: #adadad;
    }
}

.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;

    &:hover {
        color: #ffffff;
        background-color: #286090;
        border-color: #204d74;
    }
}

.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;

    &:hover {
        color: #ffffff;
        background-color: #449d44;
        border-color: #398439;
    }
}

.btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;

    &:hover {
        color: #ffffff;
        background-color: #31b0d5;
        border-color: #269abc;
    }
}

.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;

    &:hover {
        color: #ffffff;
        background-color: #ec971f;
        border-color: #d58512;
    }
}

.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;

    &:hover {
        color: #ffffff;
        background-color: #c9302c;
        border-color: #ac2925;
    }
}

.alert-box {
    margin-bottom: rem-calc(-40);
    padding-top: rem-calc(20);
    background-color: $light-gray;

    .container {
        max-width: rem-calc(1080);
    }
}

.alert-equa {
    margin-bottom: rem-calc(-40);
    padding-top: rem-calc(40);
    background-color: $light-gray;
    .alert-info {
        max-width: inherit;
    }
}

//Bootstrap Alerts
.alert {
    padding: 15px;
    margin-bottom: rem-calc(5);
    border: 1px solid transparent;
    border-radius: 4px;
}
.alert-dismissible {
    button.close {
        position: relative;
        -webkit-appearance: none;
        padding: 0;
        cursor: pointer;
        background: 0 0;
        border: 0;
        float: right;
        font-size: 21px;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        filter: alpha(opacity=20);
        opacity: 0.2;

        &:hover {
            opacity: 1;
        }
    }
}

.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    z-index: 1;
    position: relative;
}
.alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
    z-index: 1;
    position: relative;
    //max-width: 600px;
    margin: auto;
}
.alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
    z-index: 1;
    position: relative;
}
.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    z-index: 1;
    position: relative;
    //max-width: 600px;
    margin: auto;
}

.inner-image-remove {
    position: relative;
    display: inline-block;
    font-size: 0;

    .remove-icon {
        position: absolute;
        top: -5px;
        right: -15px;
        z-index: 100;
        background-color: #ec0000;
        padding: 2px 5px 3px;
        color: white !important;
        cursor: pointer;
        opacity: 1;
        text-align: center;
        font-size: 22px;
        line-height: 20px;
        border-radius: 50%;
    }

    .documents-upload-name {
        position: absolute;
        background-color: black;
        padding: 2px 9px 3px;
        word-break: break-all;
        color: white !important;
        width: 100%;
        text-align: center;
        opacity: 0.4;
        font-size: 17px;
        line-height: 20px;
    }

    .remove-icon:hover {
        background-color: #b70000;
    }
}

#resendPinCode {
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}

.employer {
    .form-group {
        position: relative;
    }
    #autoComplete_list {
        background: color.adjust(#f2f2f2, $lightness: 1%);
        margin: 0;
        list-style: none;
        position: absolute;
        left: 0;
        right: 0;
        .autoComplete_result {
            padding: 0.5rem 1rem;
            border-bottom: 1px solid darken(#f2f2f2, 10%);
            cursor: pointer;

            &:hover {
                background: darken(#f2f2f2, 4%);
            }
        }
    }
}

.promocode-wrapper {
    position: relative;

    .promocode-submit {
        position: absolute;
        border: none;
        top: 0;
        right: 0;
        height: calc(100% - 6px);
        border-radius: 0;
        background-color: #82ae33;
        color: #fff;
        padding: 0 10px;
    }

    .fa.fa-spinner.fa-spin {
        z-index: 3;
    }
}

.form {
    .radio-sources {
        display: flex;
        flex-direction: column;
    }

    label {
        &.choose-radio-label {
            display: flex;
            position: relative;
            padding: rem-calc(8) rem-calc(8) rem-calc(8) rem-calc(60);
            cursor: pointer;
            border-radius: 5px;
            border: 1px solid #81007f3d;
            color: #81007f;
            font-size: rem-calc(16);

            &:after {
                content: '';
                position: absolute;
                display: none;
            }
        }

        .choose-radio-input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked + .custom-radio {
                background-color: #fff;

                &:after {
                    display: block;
                    left: 50%;
                    top: 50%;
                    width: rem-calc(8);
                    height: rem-calc(8);
                    border-radius: 50%;
                    background: #81007f;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .custom-radio {
            position: absolute;
            left: 0;
            width: 14px;
            height: 14px;
            border: 1px solid #81007f;
            border-radius: 50%;
            top: 50%;
            transform: translate(22px, -50%);

            &:after {
                content: '';
                position: absolute;
                display: none;
            }
        }
    }
}

form[name='registration'] {
    & label:has(input[readonly='readonly']),
    label:has(select[readonly='readonly']) {
        pointer-events: none;
    }

    & .slider-wrap:has(input[readonly='readonly']) {
        pointer-events: none;
        opacity: 0.5;
    }
}

select {
    background-color: #fff;
    border: 1px solid #8c9199;
    border-radius: 0.7142857143rem;
    color: #37292b;
    font-family: inherit;
    font-size: 1.1428571429rem;
    font-weight: 400;
    height: 2.7857142858rem;
    line-height: 1.5;
    margin: 0 0 1.1428571429rem;
    padding: rem-calc(8px 24px 8px 8px);
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
