.font {
    &--1 {
        font-size: rem-calc(30);
        @include media-breakpoint-up(md) {
            font-size: rem-calc(43);
        }
    }
    &--2 {
        font-size: rem-calc(26);
        @include media-breakpoint-up(md) {
            font-size: rem-calc(32);
        }
    }
    &--3 {
        font-size: rem-calc(16);
        @include media-breakpoint-up(md) {
            font-size: rem-calc(22);
        }
    }
    &--4 {
        font-size: rem-calc(18);
    }
    &--5 {
        font-size: rem-calc(16);
        line-height: rem-calc(20);
    }
    &--6 {
        font-size: rem-calc(13);
    }
    &--7 {
        font-size: rem-calc(10);
    }
    &--8 {
        font-size: rem-calc(8);
    }
}
