// Variables
$card-width: min(360px, 100%);

.bank-id {
    .form {
        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }

    .form .documents-menu-vertical .menu-item .content {
        & p {
            @include media-breakpoint-down(sm) {
                font-size: rem-calc(14);
            }

            &.bank-id__alt-option {
                font-size: rem-calc(12);

                @include media-breakpoint-up(sm) {
                    font-size: rem-calc(14) !important;
                }
            }
        }

        & h3 {
            color: #000;
            font-family: 'Poppins', sans-serif;
            font-size: 2rem;
        }
    }

    &__cards.has-error {
        width: 90%;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            gap: 4rem;
        }

        .bank-id__card_manual {
            display: flex;
        }
    }

    &__card {
        background-color: white;
        padding: 30px 20px;
        border: 1px solid #000;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16);
        width: $card-width;
        text-align: left;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &_manual {
            opacity: 0.6;
            display: none;

            .bank-id__button {
                width: 80%;
                cursor: pointer;
                background-color: #000;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                height: 48px;
                border-radius: 8px;
                font-size: 16px;
            }
        }

        p {
            font-family: Inter, sans-serif;

            @include media-breakpoint-up(sm) {
                font-size: rem-calc(16) !important;
            }
        }
    }

    object {
        color: #000;
        font-size: 20px;
        width: 100%;
    }

    &__link {
        color: #fff;
        width: 100%;
        position: relative;
        display: block;

        &:hover {
            text-decoration: none;
            color: #fff;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        object {
            width: 100%;
        }
    }

    &__logo_sm {
        display: block;

        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    &__logo_lg {
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
        }
    }
}
