h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

h1,
h2,
h3,
h4 {
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
}

p {
    font-size: inherit;
    line-height: 1.6;
    margin-bottom: 1rem;
}

h1 {
    font-size: rem-calc(28);
}
h2 {
    font-size: rem-calc(24);
}
h3 {
    font-size: rem-calc(18);
}
h4 {
    font-size: rem-calc(14);
}
h5 {
    font-size: rem-calc(12);
}
h6 {
    font-size: rem-calc(10);
}

@media (min-width: 768px) {
    h1 {
        font-size: rem-calc(45);
    }
    h2 {
        font-size: rem-calc(36);
    }
    h3 {
        font-size: rem-calc(18);
    }
    h4 {
        font-size: rem-calc(14);
    }
    h5 {
        font-size: rem-calc(12);
    }
    h6 {
        font-size: rem-calc(10);
    }
}

a {
    color: $primary-color;
}
